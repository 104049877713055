import MobileMenu from './modules/_MobileMenu.es6';

(function() {
  'use strict';

  // The styling for this mobile menu is located in source/_patterns/04-components/mobile-menu/_mobile-menu.scss.

  if (!document.body.classList.contains('js-mobile-menu-processed')) {
    const mobileMenu = new MobileMenu();
    mobileMenu.init();
    document.body.classList.add('js-mobile-menu-processed');
  }
})();
